import React, { useEffect, useState } from 'react';
import { About, Footer, Header, Blogs, Work, Skills } from './container';
import Navbar from './components/Navbar/Navbar';
import MoveToTop from './components/MoveToTop/MoveToTop';
import './App.scss';

function App() {
  const [isTop, setIsTop] = useState(true);

  const handleScroll = () => {
    setIsTop(window.pageYOffset < 400); // Check if the user is at the top of the page
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="app">
      <Navbar />
      <Header />
      <About />
      <Blogs />
      <Work />
      <Skills />
      {/* <Testimonial /> */}
      <Footer />
      {!isTop && <MoveToTop />}
    </div>
  );
}

export default App;
