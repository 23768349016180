import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Blogs.scss';
// import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";


const Blogs = () => {
    const medium_API = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@dcpandey0";
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [onpageVal,setOnPageVal] = useState(3);

    // Function to strip HTML tags from a string
    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const formatDate = (pubDate) => {
        const date = new Date(pubDate);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                setLoading(true);
                const response = await fetch(medium_API);
                if (!response.ok) {
                    throw new Error('Failed to fetch articles');
                }
                const data = await response.json();
                setArticles(data.items.reverse());

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchArticles();
    }, []);

    const shimmerSkeleton = (
        <div className="animate-pulse">
            <div className="rounded-lg bg-gray-200 h-60 w-full mb-5"></div>
            <div className="h-5 w-3/4 bg-gray-200 rounded mb-2"></div>
            <div className="h-5 w-1/2 bg-gray-200 rounded mb-3"></div>
            <div className="h-5 w-1/4 bg-gray-200 rounded"></div>
        </div>
    );

    return (
        <>
            <h2 className="head-text font-caveat">Reach Out To <span>My Medium Blogs</span></h2>
            <section className="px-4 py-10 sm:py-20 mx-auto max-w-7xl">
                <div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
                    {loading ? (
                        // Display shimmer skeleton while loading
                        Array.from({ length: 3 }).map((_, index) => (
                            <div key={index}>{shimmerSkeleton}</div>
                        ))
                    ) : (
                        // Display articles once loaded
                        articles.map((post, index) => (
                            <a href={post.link} key={index} target="_blank" rel="noopener noreferrer">
                                <motion.div
                                    whileHover={{ scale: 1.10 }}
                                    whileInView={{ opacity: 2 }}

                                    whileTap={{ scale: 0.95 }}
                                    transition={{ duration: 0.5, type: 'tween' }}

                                    className="p-1 rounded-lg"
                                >
                                    <img
                                        src={post.description.match(/<img[^>]+src="([^"]+)"/)[1]}
                                        className="w-full h-auto object-cover sm:h-48 mb-5 bg-center rounded-lg"
                                        alt="Blog"
                                        loading="lazy"
                                    />
                                    <h3 className="mb-2  text-sm font-semibold text-gray-900">
                                        {post.title}
                                    </h3>
                                    <p className="mb-3 text-sm sm:text-md text-justify font-normal text-gray-500">
                                        {`${stripHtmlTags(post.content).substring(0, 220)}${stripHtmlTags(post.content).length > 220 ? '...' : ''}`}
                                    </p>
                                    <p className="mb-3 text-sm font-normal text-gray-500">
                                        <strong className='text-blue-900'>{post.author}</strong> • {formatDate(post.pubDate)}
                                    </p>
                                </motion.div>
                            </a>
                        ))
                    )}
                </div>
                {/* <div className='flex justify-center mt-10'>
                    <CiCircleChevLeft  className='m-4 h-12 w-12 text-blue'/>
                    <CiCircleChevRight className='m-4 h-12 w-12 text-blue'/>
                </div> */}
            </section>
        </>
    );
};

export default AppWrap(
    MotionWrap(Blogs, 'app__about'),
    'blogs',
    'app__primarybg',
);
