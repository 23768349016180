import React from 'react';
import './Navbar.scss';
import { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';
import { images } from '../../constants';

const Navbar = () => {
    const [toggle, setToggle] = useState(false);

    const handleMenuClick = () => {
        try {
            setToggle(true);
        } catch (error) {
            console.error("Error toggling menu:", error);
        }
    };

    const handleCloseClick = () => {
        try {
            setToggle(false);
        } catch (error) {
            console.error("Error closing menu:", error);
        }
    };

    return (
        <nav className="app__navbar">
            <div className="app__navbar-logo">
                <img src={images.newlogo} alt='logo' />
            </div>
            <ul className="app__navbar-links">
                {['home', 'about', 'blogs', 'work', 'skills', 'contact'].map((item) => (
                    <li className="app__flex p-text" key={`link-${item}`}>
                        <div />
                        <a href={`#${item}`}>{item}</a>
                    </li>
                ))}
            </ul>

            <div className="app__navbar-menu">
                <HiMenuAlt4 onClick={handleMenuClick} />
                {toggle && (
                    <motion.div
                        whileInView={{ x: [300, 0] }}
                        transition={{ duration: 0.55, ease: 'easeOut' }}
                    >
                        <HiX onClick={handleCloseClick} />
                        <ul>
                            {['home', 'about', 'blogs', 'work', 'skills', 'testimonial', 'contact'].map((item) => (
                                <li key={item}>
                                    <a href={`#${item}`} onClick={handleCloseClick}>
                                        {item}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </motion.div>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
