import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Footer.scss';

const Footer = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
      .then((result) => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.text);
      });
  };

  return (
    <>
      <h2 className="head-text font-caveat">Got A Message <span>For Me?</span></h2>

      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" />
          <a href="mailto:dheerajsde0@gmail.com" className="p-text">dheerajsde0@gmail.com</a>
        </div>
      </div>

      {!isFormSubmitted ? (
        <form ref={formRef} className="app__footer-form app__flex" onSubmit={sendEmail}>
          <div className="app__flex">
            <input
              className="p-text"
              required
              type="text"
              placeholder="Your Name"
              name="from_name"
            />
          </div>
          <div className="app__flex">
            <input
              className="p-text"
              required
              type="email"
              placeholder="Your Email"
              name="reply_to"
            />
          </div>
          <div>
            <textarea
              className="p-text"
              required
              placeholder="Your Message"
              name="message"
            />
          </div>
          <button type="submit" className="p-text shadow-lg shadow-yellow-900">
            {!loading ? 'Send Message' : 'Sending...'}
          </button>
        </form>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in touch!
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);
