import React from 'react';
import { BsInstagram, BsTwitterX } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import { TfiLinkedin } from 'react-icons/tfi';
import './SocialMedia.scss';

const SocialMedia = () => (
    <div className="app__social">
        <a href='https://www.linkedin.com/in/dcpandey0/' target='_blank' rel='noopener noreferrer'>
            <div className="social-icon linkedin">
                <TfiLinkedin />
            </div>
        </a>
        <a href='https://github.com/dcpandey1' target='_blank' rel='noopener noreferrer'>
            <div className="social-icon github">
                <FaGithub />
            </div>
        </a>
        <a href='https://twitter.com/dcpandey0' target='_blank' rel='noopener noreferrer'>
            <div className="social-icon twitter">
                <BsTwitterX />
            </div>
        </a>
        <a href='https://instagram.com/dcpandey1' target='_blank' rel='noopener noreferrer'>
            <div className="social-icon instagram">
                <BsInstagram />
            </div>
        </a>
    </div>
);

export default SocialMedia;
